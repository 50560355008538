import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import EventList from "../../partials/miawwhalla/EventList";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { animated, useSpring } from "@react-spring/web";

function MiawwhallaEvent() {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [events, setEvents] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    internalAPIClient
      .get(`${process.env.PUBLIC_URL}/missions`)
      .then((res) => {
        setEvents(res?.data?.data ?? []);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  const [springs, api] = useSpring(() => ({
    from: { x: 1000 },
    to: { x: 0 },
  }));

  const handleBack = () => {
    api.start({
      from: { x: 0 },
      to: { x: 1000 },
    });
    setTimeout(() => {
      return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
    }, 300);
  };

  return (
    <>
      <LayoutMiawhalla>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
          <div className="relative">
            <ProfileBox />
          </div>
          <animated.div
            className="pl-8 relative"
            style={{
              ...springs,
            }}
          >
            <div
              className="text-white/60 hover:text-white absolute z-10 my-auto h-full flex items-center -left-4 cursor-pointer hover:scale-110 transition-all"
              onClick={handleBack}
            >
              <Icon icon="mdi:chevron-right" className="w-20 h-20" />
            </div>
            <div className="absolute z-10 top-6 right-8 w-full flex justify-end">
              <div className="flex items-center justify-end gap-2">
                <button className="w-[33%]">
                  <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-mainq.webp`} alt="" className="w-full" />
                </button>
                <button className="w-[28%] cursor-not-allowed">
                  <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-sideq.webp`} alt="" className="w-full grayscale" />
                </button>
              </div>
            </div>
            <div
              className="relative w-full aspect-[16/13] bg-cover bg-right bg-no-repeat pt-32 mt-4"
              style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg-event.webp')` }}
            >
              <div className="w-11/12 ml-auto h-full overflow-y-auto overflow-x-hidden scrollbar scrollbar-w-1 scrollbar-thumb-[#66b7ff]/70 scrollbar-track-[#4191D5]/30 scrollbar-rounded-lg">
                {events !== null && events.length > 0 ? (
                  <>
                    {events.map((item) => (
                      <EventList event={item} key={item.id} />
                    ))}
                  </>
                ) : (
                  <>
                    {events === null ? (
                      <div>
                        <Icon icon="eos-icons:loading" className="mt-4 text-white" />
                      </div>
                    ) : (
                      <div>Data Not Found!</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </animated.div>
        </div>
      </LayoutMiawhalla>
    </>
  );
}

export default MiawwhallaEvent;
