import moment from "moment";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

export default function EventList({ event }) {
	const [endTime, setEndTime] = useState(null);
	const [startTime, setStartTime] = useState(null);

	useEffect(() => {
		const getStartTime = moment(event?.start_time).diff(moment.utc());
    const getEndTime = moment(event?.end_time).diff(moment.utc());
		setStartTime(getStartTime);
		setEndTime(getEndTime);
	}, []);

  return (
    <Link to={startTime > 0 ? `${process.env.PUBLIC_URL}/miawwhalla/event/${event.id}` : `${process.env.PUBLIC_URL}/miawwhalla/event/${event.id}/battle`} className={`${!event.enabled && "pointer-events-none"}`}>
      <div className="relative pl-2 hover:-translate-x-2 transition-all">
        <img src={event.banner_image} alt="Miawwhalla" className={`w-full ${!event.enabled && "grayscale"}`} />

        <div className="absolute left-[2%] top-[6%] w-[44%]">
          <img src={event.title_url} alt={event.name} className="w-full" />
        </div>

        {/* Event not started */}
        {event.enabled && startTime > 0 && (
          <div className="absolute right-4 bottom-6">
            <div className="flex items-center gap-2">
              <div className="w-64 h-5 bg-white rounded-full p-0.5">
                <div className="bg-yellow-400 rounded-full h-4 w-1/3"></div>
              </div>
              <div className="absolute right-2 font-cursive text-[11px]">
                <div className="flex items-center gap-1">
                  <Countdown date={moment.utc() + startTime} renderer={rendererCountDown} />
                  <span>to Battle</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Event has been started & not ended */}
        {startTime < 0 && endTime > 0 && (
          <div className="absolute right-4 bottom-0">
            <div className="flex items-center gap-2">
              <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`} alt="" className="w-14" />
              <div className="w-64 h-5 bg-white rounded-full p-0.5">
                <div className="bg-[#57D7BB] rounded-full h-4 w-1/3"></div>
              </div>
              <div className="absolute right-2 font-cursive text-[11px]">
                <div className="flex items-center gap-1">
                  <Countdown date={moment.utc(moment.utc().local()) + endTime} renderer={rendererCountDown} />
                  <span>Remaining</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Event has been started & ended */}
        {startTime < 0 && endTime < 0 && (
          <div className="absolute right-4 bottom-0">
            <div className="flex items-center gap-2">
              <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`} alt="" className="w-14" />
              <div className="w-64 h-5 bg-white rounded-full p-0.5">
                <div className="bg-[#57D7BB] rounded-full h-4 w-full"></div>
              </div>
              <div className="absolute right-1/3 font-cursive text-[11px]">
                <div className="flex items-center text-black gap-1">
                  <span>Ended</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}


const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return window.location.reload();
  } else {
    // let newHours = days * 24 + hours;
    return (
      <p>
        {days}<span className="text-[9px]">d</span> {hours}<span className="text-[8px]">h</span> {minutes}<span className="text-[8px]">m</span> {seconds}<span className="text-[8px]">s</span>
      </p>
    );
  }
};
