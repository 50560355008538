function CardCollection({image, title}) {
    return (
      <div className="bg-white rounded shadow hover:shadow-lg transition-all">
        <img src={image} alt="Item" className="w-full aspect-square object-cover rounded-t" />
        <div className="p-4">
          <h6 className="font-bold">{title}</h6>
        </div>
      </div>
    );
}

export default CardCollection;