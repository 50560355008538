import LayoutHome from "../layouts/LayoutHome";
import toast, { Toaster } from "react-hot-toast";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import LoadingSpinner from "../partials/LoadingSpinner";
import { useWeb3Auth } from "../utils/eth/auth";
import { UserContext } from "../context/UserContext";

function Auth() {
  const [currentUser] = useContext(UserContext);
  const navigate = useNavigate();
  const { doWeb3Auth } = useWeb3Auth();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(async () => {
  //   if (localStorage.getItem("x-auth-khuga")) {
  //     setTimeout(() => {
  //       return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
  //     }, 500);
  //   }

  //   console.log(wallet);
  //   if (wallet.connecting) {
  //     setIsLoading(true);
  //   } else if (!wallet.connecting && !wallet.connected) {
  //     setIsLoading(false);
  //   } else if (!wallet.connecting && !wallet.disconnecting && wallet.connected) {
  //     console.log("connected");

  //     // ------------------------
  //     // Web3
  //     // ------------------------

  //     // Get Nonce
  //     setIsLoading(true);
  //     console.log("Initiating Web3 Authentication");
  //     const nonce = await internalAPIClient.get(`/web3/auth?public_key=${wallet.publicKey}`).catch((e) => {
  //       setIsLoading(false);
  //       wallet.disconnect();
  //       return toast.error("Authentication Error: " + e);
  //     });

  //     console.log(`Nonce is: ${nonce?.data?.data?.nonce}`);

  //     if (nonce?.data?.data?.nonce !== undefined) {
  //       // Sign Message
  //       setIsLoading(true);
  //       console.log("Please Sign the Authentication with Wallet");
  //       try {
  //         const signature = await doWeb3Auth(wallet.publicKey, nonce?.data?.data?.nonce);

  //         // Verify
  //         setIsLoading(true);
  //         console.log("Verifying Message Signing");
  //         const verify = await internalAPIClient
  //           .post("/web3/verify", {
  //             public_key: wallet.publicKey,
  //             signature: signature,
  //           })
  //           .catch((e) => {
  //             setIsLoading(false);
  //             wallet.disconnect();
  //             return toast.error("Authentication Error: " + e);
  //           });

  //         setIsLoading(false);
  //         console.log(verify);
  //         if (verify?.data?.data?.haveNFT === true) {
  //           setTimeout(() => {
  //             location.reload();
  //             // return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
  //           }, 500);
  //         } else {
  //           wallet.disconnect();
  //           return toast.error("You don't have any NFT");
  //         }
  //       } catch (e) {
  //         setIsLoading(false);
  //         wallet.disconnect();
  //         return toast.error("Authentication Error: " + e);
  //       }
  //     }
  //   }
  // }, [wallet]);

  useEffect(async () => {
    if (localStorage.getItem("x-auth-khuga") && localStorage.getItem("metamask_address")) {
      setTimeout(() => {
        return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
      }, 500);
    }

    const auth = localStorage.getItem("x-auth-khuga");
    const metamaskAddress = localStorage.getItem("metamask_address");

    if ((auth === null || auth === undefined) && metamaskAddress !== null && metamaskAddress !== undefined) {
      setIsLoading(true);
      // Get Nonce
      // setIsLoading(true);
      console.log("Initiating Web3 Authentication");
      const nonce = await internalAPIClient.get(`/web3/auth?public_key=${metamaskAddress}`).catch((e) => {
        setIsLoading(false);
        // handleDisconnect();
        return toast.error("Authentication Error: " + e);
      });

      console.log(`Nonce is: ${nonce?.data?.data?.nonce}`);

      if (nonce?.data?.data?.nonce !== undefined) {
        // Sign Message
        setIsLoading(true);
        console.log("Please Sign the Authentication with Wallet");
        try {
          const signature = await doWeb3Auth(metamaskAddress, nonce?.data?.data?.nonce);

          // Verify
          setIsLoading(true);
          console.log("Verifying Message Signing");
          const verify = await internalAPIClient
            .post("/web3/verify-miawwhalla", {
              public_key: metamaskAddress,
              signature: signature,
            })
            .catch((e) => {
              // wallet.disconnect();
              toast.error("Authentication Error: " + e);

              setTimeout(() => {
                setIsLoading(false);
                handleDisconnect();
              }, 1000);
            });

          setIsLoading(false);
          console.log(verify);

          if (verify?.data?.data?.haveNFT === true) {
            setTimeout(() => {
              return window.location.reload();
            }, 500);
          } else {
            toast.error("You don't have any NFT");

            setTimeout(() => {
              setIsLoading(false);
              handleDisconnect();
            }, 1500);
          }
        } catch (e) {
          toast.error("Authentication Error: Failed to connect with metamask");

          setTimeout(() => {
            setIsLoading(false);
            handleDisconnect();
          }, 1000);
          return;
        }
      } else {
        toast.error("Authentication Error");

        setTimeout(() => {
          setIsLoading(false);
          // handleDisconnect();
        }, 1000);
        return;
      }
    }
  }, []);

  const handleDisconnect = () => {
    localStorage.removeItem("metamask_address");
    localStorage.removeItem("x-auth-khuga");
    localStorage.removeItem("x-user-khuga");
    window.location.reload();
  };

  const handleConnectMetamask = async () => {
    setIsLoading(true);

    if (!window.ethereum) {
      setIsLoading(false);
      toast.error("Install metamask extension!");
      return;
    }

    let accounts;
    try {
      accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    } catch (e) {
      console.log(e);
      toast.error("Failed to connect to wallet");
      setIsLoading(false);
      return;
    }

    localStorage.setItem("metamask_address", accounts[0] ?? null);
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <LayoutHome>
      {isLoading && <LoadingSpinner />}

      <div className="w-full h-screen bg-[#C2C2C2]">
        <div className="absolute z-0 inset-0 bg-cover bg-bottom bg-no-repeat bg-[url('../../public/images/bg/miawhalla-mobile.webp')] sm:bg-[url('../../public/images/bg/miawhalla.webp')]"></div>

        <div className="relative flex justify-center items-center h-full z-10">
          <div className="-translate-y-52 sm:translate-y-12">
            <div className="flex justify-center items-center">
              {!window.ethereum && isMobile ? (
                <a
                  className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all !p-0"
                  href="https://metamask.app.link/dapp/https://khuga.io/auth"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/button/connect-wallet.webp`} alt="Menu" className="h-20" />
                  <span className="absolute left-0 top-0 w-full text-center mt-6 text-white font-cursive text-lg">Connect With Your Wallet</span>
                </a>
              ) : (
                <button className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all !p-0" onClick={handleConnectMetamask}>
                  <img src={`${process.env.PUBLIC_URL}/images/button/connect-wallet.webp`} alt="Menu" className="h-20" />
                  <span className="absolute left-0 top-0 w-full text-center mt-6 text-white font-cursive text-lg">Connect With Your Wallet</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <footer className="absolute inset-x-0 bottom-0 z-10 bg-black text-white py-2 md:py-4">
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center">
          <span className="font-bold text-xs">©2023 Khuga by Khuga Labs</span>
        </div>
      </footer>

      <Toaster />
    </LayoutHome>
  );
}

export default Auth;
