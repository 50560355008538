import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { Link } from "react-router-dom";
import { first } from "lodash";
import { Icon } from "@iconify/react";


function Inventory() {
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [inventories, setInventories] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState();

  useEffect(async () => {
    if (metamaskAddress) {
      await internalAPIClient
        .get(`${process.env.PUBLIC_URL}/nft-ownerships/${metamaskAddress}/collections`)
        .then((res) => {
          const collections = res?.data?.data?.side_collection;
          if (collections) {
            setInventories(collections);
            if (collections.length > 0) {
              setSelectedInventory(first(collections));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div
      className="relative ml-[5%] h-80 lg:h-full bg-cover bg-no-repeat"
      style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/inventory.webp')` }}
    >
      <div className="relative w-[35%] h-full text-sm font-bold ml-[10%] flex items-start justify-center">
        <div className="relative h-[73%] mt-[42%]">
          {selectedInventory ? (
            <div className="relative h-full text-center">
              <img src={selectedInventory?.collection_token_image_url} alt={selectedInventory?.collection_name} className="w-5/6 aspect-square mx-auto" />
              {/* <img src="https://i.postimg.cc/9fMbxLVM/Coin-07-1.png" alt={selectedInventory?.collection_name} className="w-5/6 mx-auto" /> */}
              <h4 className="font-bold text-xl text-black mb-4">{selectedInventory?.collection_name}</h4>
              <div className="h-[25%] overflow-auto scrollbar scrollbar-w-1 scrollbar-thumb-[#66b7ff]/70 scrollbar-track-[#4191D5]/30 scrollbar-rounded-lg pr-2">
                <p className="text-sm">{selectedInventory?.collection_description}</p>
              </div>
            </div>
          ) : (
            <div className="pt-[25%]">
              <img src="/images/miawwhalla/gn.gif" alt="No Data" className="w-2/3 mx-auto mb-4 mt-8" />
              <div className="text-center font-cursive text-xs lg:text-base text-[#141F58]">
                <p>You have no items!</p>
                <p>
                  Join{" "}
                  {/* <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`} className="hover:text-amber-800">
                    Battles
                  </Link>{" "} */}
                  <button
                    className="hover:text-amber-800"
                    onClick={() => {
                      toast.dismiss();
                      toast.error("Coming Soon");
                    }}
                  >
                    Battles
                  </button>{" "}
                  to get some!
                </p>
              </div>
            </div>
          )}

          {selectedInventory?.collection_opensea_url && (
            <div className="absolute right-0 bottom-2">
              <a href={selectedInventory?.collection_opensea_url} target="_blank" className="hover:contrast-200">
                <Icon icon="simple-icons:opensea" className="w-8 h-8" />
              </a>
            </div>
          )}
        </div>
      </div>

      <div className="absolute right-[10%] pr-5 top-[33%] bottom-10 w-[33%] overflow-auto scrollbar-thin scrollbar-thumb-[#66b7ff] scrollbar-track-[#4191D5] scrollbar-rounded-lg">
        <div className="absolute w-full pr-5">
          <div className="grid grid-cols-2 gap-6">
            <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
          </div>
        </div>
        <div className="relative w-full grid grid-cols-2 gap-6">
          {inventories.length > 0 &&
            inventories.map((item) => (
              <div
                key={item.collection_id}
                className={`relative w-full aspect-square rounded-md bg-[#4191D5] border-2 border-transparent cursor-pointer transition-all ${
                  item.collection_id == selectedInventory?.collection_id && "p-0 border-black scale-100"
                } ${item.collection_id != selectedInventory?.collection_id && "scale-95"}`}
                onClick={() => setSelectedInventory(item)}
              >
                <span className="w-auto h-5 flex items-end justify-end absolute bottom-1 right-1.5 font-bold text-sm bg-white/50 backdrop-blur rounded -tracking-wider">
                  {item.balance}
                </span>
                {/* <img src="https://i.postimg.cc/dVSCW4D5/Coin-02.jpg" alt={item.collection_name} className="w-full aspect-square rounded-md bg-white" /> */}
                <img src={item?.collection_thumbnail_url} alt={item?.collection_name} className="w-full aspect-square rounded-md bg-white" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Inventory;
