import { useState } from "react";
import LayoutBase from "../layouts/LayoutBase";
import CardCollection from "../partials/card/CardCollection";
import InfiniteScroll from "react-infinite-scroller";
import CollectionLoader from "../partials/loader/CollectionLoader";

function Collections() {
  const [collectionList, setCollectionList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const loadCollectionList = (page) => {
    fetch(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/collection?page=${page}&perPage=8`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-auth-khuga": localStorage.getItem("x-auth-khuga") || "",
      },
    })
    // fetch(`http://localhost:3001/gallery?_page=${page}&_limit=8`)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        const newList = collectionList.concat(data?.data);
        setCollectionList(newList);
        if (data.length === 0) {
          setHasMoreItems(false);
        } else {
          setHasMoreItems(true);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-cursive">My Collections</h1>
        <div className="w-full overflow-auto">
          <InfiniteScroll
            threshold={10000}
            pageStart={0}
            loadMore={loadCollectionList}
            hasMore={hasMoreItems}
            loader={
              <div key={0} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {/* <CollectionLoader />
                <CollectionLoader />
                <CollectionLoader />
                <CollectionLoader /> */}
              </div>
            }
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
              {collectionList.map((item, i) => (
                <div key={i}>
                  <CardCollection image={item.collections.image} title={item.collections.name} />
                </div>
              ))}
            </div>
          </InfiniteScroll>
          {hasMoreItems ? "" : <div className="text-center py-4">All data has been loaded</div>}
        </div>
      </div>
    </LayoutBase>
  );
}

export default Collections;
