import LayoutBase from "../layouts/LayoutBase";
import Chips from "../partials/actions/Chip";
import CardMerchandise from "../partials/card/CardMerchandise";
import { useCart } from "react-use-cart";
import toast, { Toaster } from "react-hot-toast";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import { useEffect, useState } from "react";

function Merchandise() {
  const { addItem } = useCart();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [products, setProducts] = useState([]);
  const [paymentChannel, setPaymentChannel] = useState();

  useEffect(async () => {
    await internalAPIClient
      .get(`/merchandise`)
      .then((res) => {
        setProducts(res?.data?.data ?? []);
      })
      .catch(async (e) => {
        toast.error("Error: " + e);
      });

    await internalAPIClient
      .get(`/transaction-payment-channels`)
      .then((res) => {
        setPaymentChannel(res.data?.data[0] ?? '');
      })
      .catch(async (e) => {
        toast.error("Error: " + e);
      });
  }, []);

  const addItemHandler = (product) => {
    addItem(product);
    toast.success("Added to cart.", { position: "top-right" });
  };

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-cursive">Merchandise</h1>

        {/* Filter */}
        {/* <div className="space-x-2 space-y-2 mb-4">
          <Chips title="View All" isActive={true} />
          <Chips title="Hat" isActive={false} />
          <Chips title="T-Shirt" isActive={false} />
          <Chips title="Tumblr" isActive={false} />
          <Chips title="Jacket" isActive={false} />
          <Chips title="e-Money" isActive={false} />
        </div> */}

        {/* Items */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {products.map((p) => (
            <CardMerchandise
              key={p.id}
              image={p.image_url}
              title={p.name}
              desc={p.description}
              price={p.price * (paymentChannel?.rate ?? 1)}
              currency={paymentChannel?.unit}
              onAddItem={() => addItemHandler(p)}
            />
          ))}
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default Merchandise;
