import { Icon } from "@iconify/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { FavoriteCollectionsContext } from "../../context/FavoriteCollectionsContext";
import { difference, filter, find, first, map } from "lodash";
import toast from "react-hot-toast";
import { UserContext } from "../../context/UserContext";

export default function MiawwhallaCollections() {
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const [favCollections, setFavCollections, selectedFavCollection, setSelectedFavCollection] = useContext(FavoriteCollectionsContext);
  const [collections, setCollections] = useState([]);
  const [favCollectionsId, setFavCollectionsId] = useState();

  useEffect(async () => {
    if (metamaskAddress) {
      await internalAPIClient
        .get(`${process.env.PUBLIC_URL}/nft-ownerships/${metamaskAddress}/collections`)
        .then((res) => {
          if (res?.data?.data?.main_collection) {
            const favs = map(favCollections, "metadata.id");
            setFavCollectionsId(favs);
            // setCollections(filter(res?.data?.data?.main_collection, (o) => !favs.includes(o.metadata.id)));
            setCollections(res?.data?.data?.main_collection);
            console.log(res?.data?.data?.main_collection);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleSetFavorite = async (id) => {
    const getFavs = favCollections ?? [];

    if (getFavs.length === 5) {
      toast.dismiss();
      return toast.error("Maximum limit has been exceeded");
    }

    if (find(getFavs, (o) => o.metadata.id == id)) {
      toast.dismiss();
      return toast.error("Has been marked as a favorite");
    }

    let newFavs = [...getFavs, find(collections, (o) => o.metadata.id == id)];
    setFavCollections(newFavs);
    // setCollections(filter(collections, (o) => o.metadata.id != id));

    const favs = map(newFavs, "metadata.id");
    setFavCollectionsId(favs);

    await internalAPIClient.post(`${process.env.PUBLIC_URL}/nft-ownerships/${metamaskAddress}/favorites/${id}`).catch((err) => {
      console.log(err);
      toast.error("Error occured");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
  };

  const handleDeleteFavorite = async (id) => {
    const getFavs = favCollections ?? [];
    if (getFavs.length === 1) {
      toast.dismiss();
      return toast.error("You must have a favorite collection, at least 1 NFT");
    }

    // const newCollection = [...collections, ...filter(getFavs, (o) => o.metadata.id == id)];
    // setCollections(newCollection);

    setFavCollections(filter(getFavs, (o) => o.metadata.id != id));
    if (selectedFavCollection?.metadata?.id == id) {
      setSelectedFavCollection(first(filter(getFavs, (o) => o.metadata.id != id)));
    }

    const favs = map(
      filter(getFavs, (o) => o.metadata.id != id),
      "metadata.id"
    );
    setFavCollectionsId(favs);

    await internalAPIClient.delete(`${process.env.PUBLIC_URL}/nft-ownerships/${metamaskAddress}/favorites/${id}`).catch((err) => {
      console.log(err);
      toast.error("Error occured");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });

    if (id === currentUser?.avatar_id) {
      internalAPIClient
        .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/nft-ownerships/${metamaskAddress}/profile-picture`)
        .then((res) => {
          if (res?.data?.data?.profile_picture) {
            const newId = parseInt(res?.data?.data?.profile_picture?.substr(76).replace(".png", ""));
            setCurrentUser((prevState) => ({
              ...prevState,
              ...{ avatar: `https://khuga.io/khuga-preview/${newId}.webp`, avatar_id: newId },
            }));
            console.log(currentUser);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="shrink-0 p-6 relative">
        <h2 className="font-bold font-cursive text-2xl mb-2 text-white tracking-wider">Favorites</h2>
        <div className="absolute z-0 inset-0 p-6 top-10">
          <div className="grid grid-cols-5 gap-4 overflow-hidden">
            <div className="w-full aspect-square rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
            <div className="w-full aspect-square rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-4 overflow-hidden">
          {favCollections.length > 0 &&
            favCollections.map((i) => (
              <div className="text-center group w-full relative group overflow-hidden" key={i.id}>
                <button
                  onClick={() => handleDeleteFavorite(i.metadata.id)}
                  className="absolute p-1 right-1 top-1 bg-white/60 z-10 transition-all hover:scale-105 active:scale-95 rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={4}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="w-full aspect-square overflow-hidden rounded-xl group-hover:shadow-xl transition-all">
                  <div className="w-full aspect-square rounded-xl mb-1 group-hover:scale-110 transition-all duration-300">
                    <LazyLoadImage key={i} src={`https://khuga.io/khuga-preview/${i.metadata.id}.webp`} alt="?" effect="blur" className="w-full" />
                  </div>
                </div>
                <div className="flex justify-center items-center mt-2">
                  <h3 className="text-lg text-black/80">
                    <span>{i.metadata.name}</span>
                  </h3>
                </div>
              </div>
            ))}
        </div>
      </div>

      <h2 className="px-6 font-bold font-cursive text-2xl mb-2 text-white tracking-wider">
        My Collections <span className="font-sans">({collections.length})</span>
      </h2>
      <div className="w-full h-full overflow-auto scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg px-6 pb-6">
        {collections.length > 0 ? (
          <div className="grid grid-cols-5 lg:grid-cols-8 gap-4">
            {collections.map((i) => (
              <div className="text-center cursor-pointer group w-full relative group overflow-hidden" key={i.id}>
                {i.join_status === 'JOINED' && (
                  <div className="absolute z-10 right-7 top-1">
                    <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`} alt="Sword" className="w-5 aspect-square" />
                  </div>
                )}
                {favCollectionsId.includes(i.metadata.id) && (
                  <button
                    onClick={() => handleDeleteFavorite(i.metadata.id)}
                    className="absolute right-1 top-1 bg-white rounded-full p-1 z-10 transition-all hover:scale-105 active:scale-95"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={4}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                  </button>
                )}
                <div
                  className="w-full aspect-square overflow-hidden rounded-xl group-hover:shadow-xl transition-all"
                  onClick={() => handleSetFavorite(i.metadata.id)}
                >
                  <div className="w-full aspect-square rounded-xl mb-1 group-hover:scale-110 transition-all duration-300">
                    <LazyLoadImage key={i.id} src={`https://khuga.io/khuga-preview/${i.metadata.id}.webp`} alt="?" effect="blur" className="w-full" />
                  </div>
                </div>
                <div className="flex justify-center items-center mt-1">
                  <h3 className="text-sm text-black/80">
                    <span>{i.metadata.name}</span>
                  </h3>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="font-bold">All data has been loaded</div>
        )}
      </div>
    </div>
  );
}
