import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { claimKGearsReward } from "../../web3/eth/kgears";
import moment from "moment";
import toast from "react-hot-toast";
import LoadingSpinner from "../../partials/LoadingSpinner";

function MiawwhallaEventClaim() {
  const params = useParams();
  const navigate = useNavigate();
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState();
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [amount, setAmount] = useState(0);
  const [assetCollections, setAssetCollections] = useState([]);
  const [claimReward, setClaimReward] = useState();

  useEffect(async () => {
    await internalAPIClient
      .get(`${process.env.PUBLIC_URL}/missions/${params?.id}`)
      .then((res) => {
        setEvent(res?.data?.data);
        const getStartTime = moment.utc(res?.data?.data?.start_time).diff(moment.utc(moment.utc().local()));
        const getEndTime = moment.utc(res?.data?.data?.end_time).diff(moment.utc(moment.utc().local()));
        setStartTime(getStartTime);
        setEndTime(getEndTime);

        // redirect to event list when current event is not started || not ended
        if (getStartTime > 0 || getEndTime > 0) {
          return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  useEffect(async () => {
    setIsLoading(true);
    if (event) {
      await internalAPIClient
        .get(`${process.env.PUBLIC_URL}/missions/${event?.id}/assets/${metamaskAddress}`)
        .then((res) => {
          setAssetCollections(res?.data?.data);

          if (res?.data?.data && res?.data?.data.filter((i) => i.join_status === "JOINED").length > 0) {
            // setAmount(res?.data?.data && res?.data?.data.filter((i) => i.join_status === "JOINED").length);
          } else {
            return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      await internalAPIClient
        .get(`${process.env.PUBLIC_URL}/missions/${event?.id}/reward-claim/${metamaskAddress}`)
        .then((res) => {
          setClaimReward(res?.data?.data[0]);
          setAmount(res?.data?.data[0]?.amount);
        })
        .catch((err) => {
          console.log(err);
        });

      setIsLoading(false);
    }
  }, [event]);

  const handleChangeAmount = (val) => {
    if (val === "plus") {
      if (amount < claimReward?.amount) {
        setAmount(amount + 1);
      }
    } else {
      if (amount > 1) {
        setAmount(amount - 1);
      }
    }
  };

  const handleClaimReward = async () => {
    setIsLoading(true);
    const claim = await claimKGearsReward(
      metamaskAddress,
      claimReward?.token_id,
      amount,
      claimReward?.signature
    )
      .then((r) => {
        console.log(r);
        toast.success("Success claim your rewards");
        return navigate(`${process.env.PUBLIC_URL}/miawwhalla/profile`);
      })
      .catch((e) => {
        console.log(e);
      });

    setIsLoading(false);
  };

  return (
    <LayoutMiawhalla>
      {isLoading && <LoadingSpinner />}

      <div className="relative max-w-2xl mx-auto px-6 2xl:max-w-4xl mt-12 2xl:mt-24">
        <Link className="absolute z-20 top-4 right-10 transition-all" to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
          <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
        </Link>

        <div className="bg-white/80 backdrop-blur-sm p-6 rounded-xl">
          <h1 className="font-bold text-lg font-cursive text-center mb-4">Congrats! You've earned a K-Gears!</h1>
          <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/season1/sideq.webp`} alt="" className="w-1/2 h-auto mx-auto" />
          <p className="my-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Non dolor eum facilis quisquam, deserunt obcaecati nisi neque numquam necessitatibus, sed
            porro nobis? Sint laudantium aperiam iste rem minus. Eum, harum.{" "}
          </p>

          <div className="flex items-end gap-6">
            <div className="flex items-center justify-center">
              <div>
                <p className="text-sm mb-2">Total eligible rewards for minting</p>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleChangeAmount("minus")}
                    className="p-3 rounded-full bg-[#439583] text-white hover:scale-110 active:scale-100 transition-all"
                  >
                    <Icon icon="heroicons-outline:minus" />
                  </button>
                  <input type="text" value={amount} disabled className="w-full bg-gray-300 rounded cursor-not-allowed" />
                  <button
                    onClick={() => handleChangeAmount("plus")}
                    className="p-3 rounded-full bg-[#439583] text-white hover:scale-110 active:scale-100 transition-all"
                  >
                    <Icon icon="heroicons-outline:plus" />
                  </button>
                </div>
              </div>
            </div>
            <button
              onClick={() => handleClaimReward()}
              className="bg-[#439583] text-center p-3 text-lg rounded-md font-cursive block w-2/3 text-white mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
            >
              <span>Claim Reward</span>
            </button>
          </div>
        </div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaEventClaim;
