import LayoutBase from "../../layouts/LayoutBase";

function AdminDataWhitelist() {
  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-cursive">Data Whitelist</h1>
        <table className="w-full bg-white border">
          <thead className="bg-primary-700 text-white">
            <tr>
              <th className="text-left px-4 py-3">Name</th>
              <th className="text-left px-4 py-3">Wallet Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-3">John Doe 1</td>
              <td className="px-4 py-3">FS3ydLvDJwwHwJPoMK6NACTxUm1af2wkkjt83KpNKmgh</td>
            </tr>
            <tr>
              <td className="px-4 py-3">John Doe 2</td>
              <td className="px-4 py-3">FS3ydLvDJwwHwJPoMK6NACTxUm1af2wkkjt83KpNKmgh</td>
            </tr>
            <tr>
              <td className="px-4 py-3">John Doe 3</td>
              <td className="px-4 py-3">FS3ydLvDJwwHwJPoMK6NACTxUm1af2wkkjt83KpNKmgh</td>
            </tr>
          </tbody>
        </table>
      </div>
    </LayoutBase>
  );
}

export default AdminDataWhitelist;
