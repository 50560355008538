import { ethers } from "ethers";
import { Interface, FormatTypes } from "@ethersproject/abi";
import { default as KGearsABI } from "./kgears.json";

const KGEARS_CONTRACT_ADDRESS = process.env.REACT_APP_KGEARS_CONTRACT_ADDRESS;
const khugaABI = new Interface(KGearsABI).format(FormatTypes.full);
const RPC_PROVIDER = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_KHUGA_JSON_RPC_NODE);
const KGearsContractReader = new ethers.Contract(KGEARS_CONTRACT_ADDRESS, KGearsABI, RPC_PROVIDER);

export const claimKGearsReward = async (destAddress, tokenId, amount, signature) => {
    if (!window.ethereum) throw "No Injected Web3 Provider Found";
    const ethereum = window.ethereum;
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    const provider = new ethers.providers.Web3Provider(ethereum);
    const walletAddress = accounts[0];
    const signer = provider.getSigner(walletAddress);
    console.log(walletAddress);

    const kgearsContract = new ethers.Contract(KGEARS_CONTRACT_ADDRESS, KGearsABI, signer);
    const claimReward = await kgearsContract.populateTransaction.claimReward(destAddress, tokenId, amount, signature);
    claimReward.chainId = process.env.REACT_APP_KHUGA_NFT_CHAIN_ID;
    claimReward.gasLimit = 155000;
    
    const claimRewardTx = await signer.sendTransaction(claimReward).catch((e) => {
        throw e.error.data.data.message;
    });

    const txReceipt = await claimRewardTx.wait();
    if (txReceipt.status === 0)
        throw new Error("Approve transaction failed");

    return {
        txHash: claimRewardTx.hash,
        receiptStatus: txReceipt.status
    }
};